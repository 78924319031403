import React from "react"
import PropTypes from "prop-types"

import { Container, Nav, Navbar } from "react-bootstrap"

const Header = ({ siteTitle }) => (
  <>
    <Navbar bg="dark" expand="lg" variant="dark" fixed="top">
      <Container>
        <Navbar.Brand href="/">{siteTitle}</Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto">
            <Nav.Link href="/work">Work</Nav.Link>
            {/* <Nav.Link href="">Blog</Nav.Link> */}
            {/* <Nav.Link href="">events</Nav.Link> */}
            <Nav.Link href="/rates">rates</Nav.Link>
            <Nav.Link href="/contact">contact</Nav.Link>
            {/* <Nav.Link href="">shop</Nav.Link> */}
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
