import React from "react"
import { library } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { graphql, useStaticQuery } from "gatsby"
library.add(fab)

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          social {
            name
            handle
            link
            icon
          }
        }
      }
    }
  `)

  const socials = data.site.siteMetadata.social
  console.log(socials)

  return (
    <>
      <footer>
        <div className="container">
          <h3>FOLLOW US</h3>
          <span className="footerSocial">
            {socials.map(node => {
              return (
                <>
                  <a
                    href={node.link}
                    aria-label={`${node.name} @${node.handle}`}
                  >
                    <FontAwesomeIcon
                      icon={["fab", node.icon]}
                      size="2x"
                      className="icon"
                      fixedWidth
                    />
                  </a>
                </>
              )
            })}
            {/*<FontAwesomeIcon icon={["fab", "vimeo-v"]} /> */}
          </span>
          <p>
            {new Date().getFullYear()} &copy; Aspire The Empire LLC. All rights
            reserved.{" "}
          </p>
        </div>
      </footer>
    </>
  )
}

export default Footer
